<template>
  <b-row
    align-v="start"
    class="p-2"
  >
    <b-col
      v-if="title"
      cols="12"
      class="font-weight-bold text-uppercase font-small-3"
    >
      {{ title }}
    </b-col>
    <b-col
      v-if="autoCols"
      v-for="profileField in profileFields"
      :key="profileField.label"
      cols="auto"
      class="pb-1"
    >
      <slot :name="profileField.label">
        <div class="font-weight-bold">
          {{ profileField.label }}:
        </div>
        <div v-if="!profileField.link" class="text-light">
          {{ profileField.value }}
        </div>
        <b-link
          v-else
          class="text-indigo"
          @click="$router.push({ name: profileField.routeName , params: { id: profileField.id } })"
        >
          <u>{{ profileField.value }}</u>
        </b-link>
        <span v-if="profileField.aditionalInfo" class="text-light d-flex" style="padding-top: 5px;">
          {{ profileField.aditionalInfo }}
        </span>
      </slot>
    </b-col>
    <b-col
      v-if="!autoCols"
      v-for="profileField in profileFields"
      :key="profileField.label"
      xl="2"
      lg="3"
      md="4"
      cols="6"
      class="pb-1"
    >
      <slot :name="profileField.label">
        <div class="font-weight-bold">
          {{ profileField.label }}:
        </div>
        <div v-if="!profileField.link" class="text-light">
          {{ profileField.value }}
        </div>
        <b-link
          v-else
          class="text-indigo"
          @click="$router.push({ name: profileField.routeName , params: { id: profileField.id } })"
        >
          <u>{{ profileField.value }}</u>
        </b-link>
        <span v-if="profileField.aditionalInfo" class="text-light d-flex" style="padding-top: 5px;">
          {{ profileField.aditionalInfo }}
        </span>
      </slot>
    </b-col>
    <b-col
      v-show="observationsVisible"
      cols="12"
      xl="12"
      lg="12"
      md="12"
    >
      <div class="font-weight-bold">
        Descripción / Observaciones:
      </div>
      <div class="text-light w-75">
        {{ observations }}
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ProfilePanel',
  props: {
    profile: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    autoCols: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    profileFields() {
      return this.profile.filter(profileField => profileField.type !== 'observations')
    },
    observationsVisible() {
      if (!this.profile) {
        return false
      }

      return this.profile.some(profileField => profileField.type === 'observations')
    },
    observations() {
      if (!this.profile) {
        return 'Sin Información'
      }

      const observationsField = this.profile.find(profileField => profileField.type === 'observations')
      return observationsField ? observationsField.value : 'Sin Información'
    },
  },
}
</script>

<style scoped>

</style>
