<template>
  <div>
    <b-card no-body>
      <b-overlay
        :show="loading"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
        >
        <b-row
          v-if="titleVisible"
          align-v="center"
          align-h="between"
          no-gutters
          class="m-0 py-1 px-2"
        >
          <b-col
            class="text-uppercase font-weight-bold d-flex align-items-center py-1"
            cols="auto"
          >
            <slot name="title">
              {{ title }}
            </slot>
          </b-col>
          <b-col cols="auto">
            <b-row class="no-gutters">
              <b-col cols="auto"><template v-if="actionsVisible">
                <slot name="actions">
                  <b-link
                    v-if="editActionVisible"
                    v-access="{
                      resource: resource,
                      resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
                    }"
                    class="d-inline-block px-50 text-indigo"
                    @click.stop="$emit('edit-icon-click')"
                  >
                    <feather-icon
                      v-b-tooltip.hover 
                      title="Editar"
                      icon="Edit2Icon"
                      size="18"
                    />
                  </b-link>
                  <b-link
                    v-if="deleteActionVisible"
                    v-access="{
                      resource: resource,
                      resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
                    }"
                  >
                    <span
                      class="d-inline-block px-50 text-danger"
                      @click.stop="handleDeleteButtonClick()"
                    >
                      <feather-icon
                        v-b-tooltip.hover 
                        title="Eliminar"
                        icon="TrashIcon"
                        size="18"
                      />
                    </span>
                  </b-link>
                </slot>
              </template>
              </b-col>
              <b-col
                cols="auto"
                class="d-flex align-items-center"
              >
                <template v-if="collapsable">
                  <b-link
                    class="px-50"
                    @click="handleCollapseLinkClick"
                  >
                    <FeatherIcon
                      v-if="!collapse"
                      icon="ChevronDownIcon"
                    />
                    <FeatherIcon
                      v-else
                      icon="ChevronUpIcon"
                    />
                  </b-link>
                </template>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <slot name="title-free">
        </slot>
        <hr class="m-0">
        <b-collapse v-model="collapse">
          <slot />
        </b-collapse>
      </b-overlay>
    </b-card>
    <template v-if="footerVisible">
      <slot name="footer">
        <b-row
          align-v="center"
          align-h="end"
        >
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              :disabled="loading"
              @click="$emit('save-button-click')"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </slot>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    resource: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    titleVisible: {
      type: Boolean,
      default: true,
    },
    footerVisible: {
      type: Boolean,
      default: true,
    },
    actionsVisible: {
      type: Boolean,
      default: false,
    },
    editActionVisible: {
      type: Boolean,
      default: true,
    },
    deleteActionVisible: {
      type: Boolean,
      default: true,
    },
    initialCollapse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      collapse: this.initialCollapse,
    }
  },
  methods: {
    handleCollapseLinkClick() {
      if (!this.collapsable) {
        return
      }

      this.collapse = !this.collapse
    },
    async handleDeleteButtonClick() {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el registro.',
      })

      if (!response.isConfirmed) {
        return
      }

      this.$emit('delete-confirmation')
    },
    setCollapse(collapse) {
      this.collapse = collapse
    },
  },
}
</script>

<style scoped>

</style>
