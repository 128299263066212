<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-row
        class="p-2"
        align-v="center"
        align-h="between"
      >
        <b-col
          class="font-weight-bold d-flex align-items-center"
          cols="auto"
        >
          <span class="text-uppercase">documentación</span>
        </b-col>
        <b-col cols="auto">
          <BaseFileInput
            v-if="!readonly"
            class="d-inline"
            :multiple="multiple"
            @change="handleFileInputChange"
          >
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2 mr-1"
            >
              {{ addLinkText }}
            </b-button>
          </BaseFileInput>
          <b-link @click="collapse = !collapse">
            <FeatherIcon
              v-if="!collapse"
              icon="ChevronDownIcon"
            />
            <FeatherIcon
              v-else
              icon="ChevronUpIcon"
            />
          </b-link>
        </b-col>
      </b-row>
      <hr class="m-0">
      <b-collapse v-model="collapse">
        <b-row
          v-show="documentationRowVisible"
          class="px-2 pt-2 pb-1"
          align-v="start"
        >
          <b-col
            v-for="file in value"
            :key="file.id"
            cols="auto"
          >
            <b-link
              class="text-decoration-none text-indigo"
              @click="handleDownload(file)"
            >
              <u>{{ file.name }}</u>
            </b-link>
            <b-link v-show="!readonly">
              <span
                class="d-inline-block px-50 text-danger"
                @click="handleDeleteDocumentLinkClick(file.id)"
              >
                <feather-icon
                  v-b-tooltip.hover 
                  title="Eliminar"
                  icon="TrashIcon"
                  size="18"
                />
              </span>
            </b-link>
          </b-col>
        </b-row>
        <b-row
          v-show="!hideObservations"
          class="px-2 pt-1 pb-2"
        >
          <b-col>
            <div class="font-weight-bold pb-1">
              Observaciones:
            </div>
            <pre class="text-light w-75" style="background: #ffff;">{{ observations }}</pre>
          </b-col>
        </b-row>
      </b-collapse>
    </b-overlay>
  </b-card>
</template>

<script>
import Vue from 'vue'
import BaseFileInput from '@/components/ui/file/BaseFileInput.vue'
import ApiRestService from '@/api/base-api'

export default {
  name: 'BaseDocumentationCard',
  components: { BaseFileInput },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    resource: {
      type: Object,
      default: () => {},
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    addLinkText: {
      type: String,
      default: '+ Adjuntar archivo',
    },
    observations: {
      type: String,
      default: null,
    },
    deleteEndPoint: {
      type: String,
      default: null,
    },
    updateEndPoint: {
      type: String,
      default: null,
    },
    hideObservations: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      collapse: true,
    }
  },
  computed: {
    documentationRowVisible() {
      return this.value && this.value.length > 0
    },
  },
  methods: {
    async handleDownload(file) {
      this.loading = true
      try {
        await ApiRestService.downloadArchive(file.id, file.name)
      } finally {
        this.loading = false
      }
    },
    async handleFileInputChange(files) {
      if (!files || files.length === 0 || !this.updateEndPoint) {
        return
      }

      this.loading = true
      const resource = {
        ...this.resource,
        documentation: files,
      }

      try {
        const response = await Vue.prototype.$http.post(this.updateEndPoint, resource)
        const documentation = response.data.data.documentation
        this.$emit('input', documentation)
        this.$emit('documentation-updated', documentation)
        this.$toast('Archivos guardados con éxito.')
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al guardar los archivos adjuntos. Por favor intentelo de nuevo mas tarde.')
      }

      this.loading = false
    },
    async handleDeleteDocumentLinkClick(documentId) {
      if (!documentId || !this.deleteEndPoint) {
        return
      }

      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'El documento se borrará permanentemente',
      })

      if (!response.isConfirmed) {
        return
      }

      await this.deleteDocument(documentId)
    },
    async deleteDocument(documentId) {
      this.loading = true
      try {
        await Vue.prototype.$http.delete(`${this.deleteEndPoint}/${documentId}`)
        this.$toast('Documento borrado con éxito.')
        const documents = this.value.filter(document => document.id !== documentId)
        this.$emit('input', documents)
        this.$emit('document-deleted', documents)
      } catch (error) {
        console.error(error)
        this.$toast.error('No se pudo borrar el documento. Inténtelo de nuevo mas tarde.')
      }
      this.loading = false
    },
    setCollapse(collapse) {
      this.collapse = collapse
    },
  },
}
</script>

<style scoped>
</style>
