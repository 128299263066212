<template>
  <div>
    <a @click="handleFileInputClick">
      <slot>Subir Archivos</slot>
    </a>
    <b-form-file
      v-show="false"
      ref="base-input-file"
      v-model="model"
      :multiple="multiple"
      @input="onFileInputChange"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseFileInput',
  props: {
    value: {
      type: [Array, File],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
  },
  methods: {
    handleFileInputClick() {
      this.$refs['base-input-file'].$el.childNodes[0].click()
    },
    onFileInputChange(files) {
      this.$emit('change', this.multiple ? files : [files])
    },
    cleatFileInput() {
      this.$refs['base-input-file'].reset()
      this.value = null
    },
  },
}
</script>

<style scoped>
</style>
