<template>
  <b-badge
    pill
    :variant="variant || statusBadgeData.badgeColor"
    class="px-1 d-inline-flex align-items-center"
  >
    <feather-icon
      :icon="statusBadgeData.icon"
      size="25"
      :color="statusBadgeData.iconColor"
    />
    <span :class="'text-capitalize font-weight-light' + statusBadgeData.textColor + ' badge-text'">{{ text || statusBadgeData.status }}</span>
  </b-badge>
</template>

<script>
import * as STATUS_BADGE_DATA from '@/shared/constants/statusBadges'

export default {
  name: 'StatusBadge',
  props: {
    status: {
      type: String,
      default: 'success',
    },
    text: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
  },
  computed: {
    statusBadgeData() {
      if (!this.status) {
        return STATUS_BADGE_DATA.STATUS_BADGE_NOT_STATUS
      }

      const computedStatus = this.status.toLowerCase()
      const statusBadgeKey = Object.keys(STATUS_BADGE_DATA).find(key => computedStatus === STATUS_BADGE_DATA[key].status)

      return statusBadgeKey ? STATUS_BADGE_DATA[statusBadgeKey] : STATUS_BADGE_DATA.STATUS_BADGE_NOT_STATUS
    },
  },
}
</script>

<style scoped>
</style>
