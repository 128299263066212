<template>
  <div>
    <NonConformityProfileCard :non-conformity="nonConformity" />
    <BaseDocumentationCard
      v-model="documentation"
      hide-observations
      :resource="nonConformity"
      :delete-end-point="deleteEndPoint"
      :update-end-point="updateEndPoint"
    />
    <NonConformityActionCard :non-conformity="nonConformity" />
  </div>
</template>

<script>
import NonConformitiesApi from '@/api/non-conformities-api'
import { mapActions } from 'vuex'
import NonConformityProfileCard from '@/components/non-conformities/card/NonConformityProfileCard.vue'
import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'
import NonConformityActionCard from '@/components/non-conformities/card/NonConformityActionCard.vue'

export default {
  components: { NonConformityActionCard, BaseDocumentationCard, NonConformityProfileCard },
  data() {
    return {
      nonConformity: null,
    }
  },
  computed: {
    documentation: {
      get() {
        return this.nonConformity?.documentation || []
      },
      set(value) {
        if (!this.nonConformity) {
          return
        }

        this.nonConformity.documentation = value
      },
    },
    deleteEndPoint() {
      return this.nonConformity ? `/non-conformity/delete_doc/${this.nonConformity.id}` : null
    },
    updateEndPoint() {
      return this.nonConformity ? `/non-conformities/${this.nonConformity.id}` : null
    },
  },
  mounted() {
    this.loadNonConformity()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async loadNonConformity() {
      this.setLoading(true)
      try {
        const response = await NonConformitiesApi.get(this.$route.params.id)
        this.nonConformity = response.data
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
