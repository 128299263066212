import { BaseApi } from '@/api/base-api'
import Vue from 'vue'
import DownloadService from '@/shared/services/download-service'

class NonConformitiesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  // eslint-disable-next-line class-methods-use-this
  getOrigins() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get('/non-conformity-origins')
        resolve(response.data.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al obtener el recurso. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getIssuers() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.get('/non-conformity-issuers')
        resolve(response.data.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al obtener el recurso. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  async printNonConformity(nonConformityId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/print-non-conformity/${nonConformityId}`, {}, { responseType: 'blob' })
        const filename = DownloadService.getFilenameFromResponse(response)
        DownloadService.resolveAndDownloadBlob(response.data, filename)
        resolve()
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Error al descargar el archivo. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }

  async deleteFiles(resourceId, files, fileResource) {
    return new Promise(async (resolve, reject) => {
      try {
        const promises = files.map(file => Vue.prototype.$http.delete(`${this.resource}/delete_doc/${resourceId}/${file.id}/${fileResource}`))
        await Promise.all(promises)
        resolve()
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al eliminar los documentos. Por favor inténtelo de nuevo más tarde.')
        reject(error)
      }
    })
  }
}

export default new NonConformitiesApi('non-conformities')
