<template>
  <BaseCard
    title="acción correctiva"
    :footer-visible="false"
  >
    <b-row class="p-2">
      <b-col cols="auto">
        <b-form-checkbox
          :checked="nonConformityPreventive"
          disabled
          value="1"
          :unchecked-value="null"
        >
          Preventiva
        </b-form-checkbox>
      </b-col>
      <b-col cols="auto">
        <StatusBadge :status="nonConformityActionStatus" />
      </b-col>
    </b-row>
    <ProfilePanel :profile="nonConformityActionProfile" />
    <b-row class="px-2 py-1">
      <b-col cols="12">
        <div class="font-weight-bold">
          Acción correctiva:
        </div>
        <div class="text-light">
          {{ nonConformity ? nonConformity.corrective_preventive_action : '' }}
        </div>
      </b-col>
      <b-col
        cols="12"
        class="py-1"
      >
        <div class="font-weight-bold">
          Evaluación de la eficacia:
        </div>
        <div class="text-light">
          {{ nonConformity ? nonConformity.evaluation_effectiveness_preventive_corrective_action : '' }}
        </div>
      </b-col>
    </b-row>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'

export default {
  name: 'NonConformityActionCard',
  components: { ProfilePanel, StatusBadge, BaseCard },
  props: {
    nonConformity: {
      type: Object,
      default: null,
    },
  },
  computed: {
    nonConformityPreventive() {
      return this.nonConformity?.its_preventive
    },
    nonConformityActionStatus() {
      return this.nonConformity?.status_action?.name
    },
    nonConformityActionDateClose() {
      if (!this.nonConformity) {
        return ''
      }

      return this.$options.filters.formatDate(this.nonConformity.non_conformity_date_close_action) || ''
    },
    nonConformityActionProfile() {
      return [
        { label: 'Tipo', value: '' },
        { label: 'Departamento afectado', value: this.nonConformity?.department_affected_action || '' },
        { label: 'Fecha de cierre', value: this.nonConformityActionDateClose },
        { label: 'Responsable', value: this.nonConformity?.implementation_resp || 'Sin responsable' },
        { label: 'Periodo de implementación', value: this.nonConformity?.implementation_period || 'Sin periodo de implementación' },
      ]
    },
  },
}
</script>

<style scoped>

</style>
